@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  @apply font-roboto text-gray-700;
}

body,
html {
  @apply overflow-x-hidden md:overflow-x-auto;
}

input,
select,
textarea {
  @apply outline-none;
}

section {
  @apply py-20;
}

.hover-primary-color {
  @apply hover:text-primary transition duration-300;
}

.hover-brigher-color {
  @apply hover:brightness-110 transition duration-300;
}

/* CUSTOM SWIPER */
:root {
  --swiper-theme-color: black !important;
  --swiper-navigation-size: 15px !important;
}

.swiper-button-prev {
  --swiper-navigation-size: 30px !important;
  z-index: 20 !important;
}

.swiper-button-next {
  --swiper-navigation-size: 30px !important;
  z-index: 20 !important;
}

/* new custom slider */

/* FlashSaleSlider.css */

.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Set color to ensure visibility */
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  z-index: 10; /* Bring buttons to the front */
}

.swiper-button-next {
  right: -14px; /* Moves the next button slightly outside the slider */
}

.swiper-button-prev {
  left: -14px; /* Moves the previous button slightly outside the slider */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.5rem; /* Increase icon size */
}

/* REACT IMAGE GALLERY */
.image-gallery-thumbnail {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover; /* Ensure images cover the thumbnail area */
  overflow: hidden;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  border-color: #ed2027; /* Border color for active/hovered thumbnails */
}

.image-gallery-slide {
  height: 450px; /* Set your desired fixed height */
}

.image-gallery-image {
  height: 100%;
  width: auto; /* Adjusts the width to maintain the aspect ratio */
  object-fit: cover; /* Crops image if it doesn’t fill the height */
}

/* CUSTOM SCROLLBAR*/
.custom-scrollbar ::-webkit-scrollbar {
  @apply w-[10px];
}

.custom-scrollbar ::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.custom-scrollbar ::-webkit-scrollbar-button {
  @apply hidden;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  @apply bg-primary rounded-full hover:bg-primary-dark transition duration-300;
}

/* CUSTOM SELECTION TEXT COLOR */
::selection {
  @apply text-black bg-primary;
}

/* dành cho phần header, responsive cho mobile */

/* Header Styles */
header .animate__wobble {
  animation: wobble 0.5s;
}
/* Drawer Animation */
@keyframes wobble {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

/* header .hidden.md\\:flex {
  display: none;
}

@media (min-width: 768px) {
  header .hidden.md\\:flex {
    display: flex;
  }
} */

/* Các lớp responsive chỉ áp dụng trên mobile */
/* .md\\:hidden {
  display: none;
}

.hidden.md\\:flex {
  display: none;
} */

@media (min-width: 768px) {
  /* Các thành phần dành cho desktop */
  /* .hidden.md\\:flex {
    display: flex;
  }
  .md\\:hidden {
    display: none;
  } */
}
